import React from "react";
import "./Loader.css";

const Loader = ({ isLoading }) => {
  if (!isLoading) return null;
  return (
    <div id="loader" className="loader">
      <div className="typewriter">
        <div className="slide">
          <i></i>
        </div>
        <div className="paper"></div>
        <div className="keyboard"></div>
      </div>
      <p>Loading ...</p>
    </div>
  );
};

export default Loader;
