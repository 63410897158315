import React from "react";
import "./Cardlist.css";
import Card from "../Card/Card";
import mananRecords from "../../../mananRecords.json";

const Cardlist = () => {
  return (
    <div>
      <section className="card-list">
        {mananRecords &&
          mananRecords.map((card) => {
            return <Card card={card} key={card.id} />;
          })}
      </section>
    </div>
  );
};

export default Cardlist;
