import React from "react";
import "../common.css";

const AboutManan = () => {
  return (
    <div className="container">
      <div className="alert alert-light box" role="alert">
        <div className="title">
          <h2>
            <b>मनन क्या है?</b>
          </h2>
        </div>
        <div className="paragraph">
          <p align="justify">
            प्रगत संगणन विकास केन्द्र (सी-डैक), भारत सरकार की प्रसिद्ध वैज्ञानिक
            संस्था होने का दायित्व निर्वाह करते हुए कम्प्यूटर तकनीकी द्वारा
            बहुआयामी उन्नति के लिए समर्पित है, विशेषतः तब, जब कि इलेक्ट्रॉनिकी
            और सूचना प्रौद्योगिकी की बढ़ती विमाएँ विश्व भर की सर्वमुखी प्रगति में
            महत्वपूर्ण भूमिका निभा रही हैं। भारत राष्ट्र के नव निर्माण में
            तकनीकी के साथ साथ इसकी विलक्षण संस्कृति का आधार सर्वोपरि है। भारतीय
            सभ्यता सर्वांगीणता, विशालता, उदारता, प्रेम और सहिष्णुता की दृष्टि से
            अन्य संस्कृतियों की अपेक्षा अग्रणी स्थान रखती है। हमारी सभ्यता को इस
            उच्च स्थान तक लाने में भारतीय भाषाओं की अपनी ही भूमिका है। और इसमें
            कोई दो मत नहीं कि भारतीय भाषाओं में हिंदी भाषा एक तरह से सर्वग्राही
            है जिसमें खुले भाव से दूसरी भाषाओं के शब्दों को स्वीकार किया है।
            तकनीकी विकास और हिंदी भाषा संस्कृति के अद्भुत संयोजन का एक अनूठा
            माध्यम है मनन !!!
          </p>
          <p align="justify">
            दूसरे शब्दों में मनन केवल एक हिंदी पत्रिका मात्र न होकर चिंतन उपरांत
            शब्दों-संकेतों के सूक्ष्म प्रयोग द्वारा भावों-विचारों का सुंदर उद्भव
            और भाषा की अभिव्यक्ति का निर्मल उद्गम है।
          </p>
          <p align="justify">
            वैश्विक ग्राम (ग्लोबल विलेज) की संकल्पना के साथ प्रौद्योगिकी के इस
            अधिकतम ज्ञान का सार्थक उपयोग करते हुए सीडैक दिल्ली के कर्मचारियों ने
            संजोये हैं न केवल तकनीकी जानकारी और ज्ञान वर्धक लेख, अपितु रोचक
            कहानियाँ, सुरीली कवितायें और अनुभवपूर्ण वृतांत। सीडैक दिल्ली परिवार
            के परिवार जन भी पीछे नहीं रहे हैं और उन्होनें सहयोग दिया है अबूझी
            पहेलियों, खट्टी-मीठी कृतियों और नटखट नन्ही चित्रकारियों के साथ ।
            होनहार व उभरते रचनाकारों का मनोबल बढाने के लिए प्राप्त रचनाओं में से
            उत्कृष्ट रचनाओं का चयन करके यह प्रयास किया गया है कि उन्हें उचित
            प्रोत्साहन पुरस्कार भी दिए जाएं ।
          </p>
          <p align="justify">
            हमारे इस प्रयास के बारे में हमें आपकी प्रतिक्रियाओं की प्रतीक्षा
            रहेगी जो कि अधिक उत्साह के साथ आगामी अंकों को ज्ञानवर्धक और रोचक
            बनाने हेतु हमारे लिए मार्गदर्शक और प्रेरणादायी होगी।
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutManan;
