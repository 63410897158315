import React from "react";
import "./ControlPanel.css";

const ControlPanel = (props) => {
  const { pageNumber, numPages, setPageNumber, scale, setScale } = props;

  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  const firstPageClass = isFirstPage ? "disabled" : "clickable";
  const lastPageClass = isLastPage ? "disabled" : "clickable";

  const goToFirstPage = () => {
    if (!isFirstPage) setPageNumber(1);
  };

  const goToPreviousPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  };

  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1);
  };

  const goToLastPage = () => {
    if (!isLastPage) setPageNumber(numPages);
  };

  const onPageChange = (e) => {
    const { value } = e.target;
    setPageNumber(Number(value));
  };

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOutClass = isMinZoom ? "disabled" : "clickable";
  const zoomInClass = isMaxZoom ? "disabled" : "clickable";

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  return (
    <div className="control-panel">
      <div className="controls">
        <i
          className={`fa-solid fa-backward-fast ${firstPageClass}`}
          title="First Page"
          onClick={goToFirstPage}
        />
        <i
          className={`fa-solid fa-backward ${firstPageClass}`}
          title="Previous Page"
          onClick={goToPreviousPage}
        />
        <input
          name="pageNumber"
          type="number"
          min={1}
          max={numPages || 1}
          className="indexInput"
          value={pageNumber}
          onChange={onPageChange}
        />
        /{numPages}
        <i
          className={`fa-solid fa-forward ${lastPageClass}`}
          title="Next Page"
          onClick={goToNextPage}
        />
        <i
          className={`fa-solid fa-forward-fast ${lastPageClass}`}
          title="Last Page"
          onClick={goToLastPage}
        />
      </div>
      <div className="zoomControls">
        <i
          className={`fa-solid fa-magnifying-glass-minus ${zoomOutClass}`}
          title="Zoom Out"
          onClick={zoomOut}
        />
        <span>{(scale * 100).toFixed()}%</span>
        <i
          className={`fa-solid fa-magnifying-glass-plus ${zoomInClass}`}
          title="Zoom In"
          onClick={zoomIn}
        />
      </div>
    </div>
  );
};

export default ControlPanel;
