import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import cdacLogo from "../../../assets/images/cdacLogo.png";
import cdacOldLogo from "../../../assets/images/appLogoIcon.png";
import "./Header.css";

const Header = () => {
  const [isMobileView, setIsMobileView] = useState(false);

  const navigate = useNavigate();

  function navigateToHome() {
    navigate("/");
  }

  useEffect(() => {
    // Update the view whenever the window is resized
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 800); // Change the breakpoint as per your requirements
    };

    // Initial view check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="header">
      <div>
        {isMobileView ? (
          <img
            className="header-img"
            src={cdacLogo}
            alt="cdacLogo"
            onClick={navigateToHome}
          />
        ) : (
          <img
            className="header-img"
            src={cdacOldLogo}
            alt="cdacLogo"
            onClick={navigateToHome}
          />
        )}
      </div>

      <input type="checkbox" id="toggler" />
      <label htmlFor="toggler">
        <i className="fa-solid fa-bars barIcon"></i>
      </label>
      <div className="menu">
        <ul className="list">
          <li>
            <b>
              <Link to="/">मुख पृष्ठ</Link>
            </b>
          </li>
          <li>
            <b>
              <Link to="/cdac">सी-डैक दिल्ली</Link>
            </b>
          </li>
          <li>
            <b>
              <Link to="/manan">मनन क्या है?</Link>
            </b>
          </li>
          <li>
            <b>
              <Link to="/contactus">संपर्क सूत्र</Link>
            </b>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
