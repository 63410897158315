import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <small>
        &copy;{new Date().getFullYear()} सी-डैक। सर्वाधिकार सुरक्षित।
      </small>
      <small>सॉफ्टवेयर प्रौद्योगिकी समूह, सी-डैक दिल्ली</small>
    </div>
  );
};

export default Footer;
