import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Common/Header/Header";
import Footer from "./components/Common/Footer/Footer";
import Viewpage from "./pages/Viewpage/Viewpage";
import AboutCdac from "./pages/AboutCdac/AboutCdac";
import AboutManan from "./pages/AboutManan/AboutManan";
import ContactUs from "./pages/ContactUs/ContactUs";
import NotFound from "./pages/NotFound/NotFound";
import Home from "./pages/Home/Home";
import "./App.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <div className="application">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/view" element={<Viewpage />} />
            <Route path="/manan" element={<AboutManan />} />
            <Route path="/cdac" element={<AboutCdac />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
