import React from "react";
import mananLog from "../../../assets/images/mananLogo.png";
import "./Heroimage.css";

const Heroimage = () => {
  return (
    <div className="heroimage">
      <div className="row">
        <img
          src={mananLog}
          // style={{ width: "100%", height: "40rem" }}
          className="img-fluid heroimage__image"
          alt="..."
        />
      </div>
      <div className="row">
        <h4 className="heroimage__subtitle">
          <b>सी-डैक दिल्ली केंद्र की वार्षिक हिंदी पत्रिका</b>
        </h4>
      </div>
    </div>
  );
};

export default Heroimage;
