import React from "react";
import "../common.css";

const AboutCdac = () => {
  return (
    <div className="container">
      <div className="alert alert-light box" role="alert">
        <div className="title">
          <h2>
            <b>सी-डैक दिल्ली</b>
          </h2>
        </div>
        <div className="paragraph">
          <p align="justify">
            दिल्ली में केंद्र की स्थापना 1989 में डिजिटल सिग्नल प्रोसेसिंग
            (डीएसपी) के क्षेत्र में अनुसंधान और विकास के लिए एक परियोजना सेल के
            रूप में की गई थी। दिल्ली में और तत्कालीन इलेक्ट्रॉनिक्स विभाग (डीओई)
            के पास होने के कारण, केंद्र डीओई और अन्य सभी सी-डैक केंद्रों के बीच
            विभिन्न समन्वय गतिविधियों में शामिल था।
          </p>
          <p align="justify">
            दिल्ली केंद्र के फोकस क्षेत्र साइबर सुरक्षा, शिक्षा और प्रशिक्षण,
            ई-गवर्नेंस समाधान, विस्तारित वास्तविकता, अंतर्राष्ट्रीय सहयोग,
            न्यूरो-कॉग्निटिव एआई और सॉफ्टवेयर प्रौद्योगिकी हैं।
          </p>
          <p align="justify">
            सी-डैक दिल्ली ने आईटी उद्योग की बढ़ती आवश्यकताओं को पूरा करने के लिए
            केंद्रों में उपलब्ध उत्पाद और प्रौद्योगिकियों को बढ़ावा देने के लिए
            अंतर्राष्ट्रीय सहयोग प्रभाग (आईसीडी) की स्थापना की है। आईसीडी आईसीटी
            के क्षेत्र में ज्ञान, विशेषज्ञता और अनुभव साझा करके विभिन्न देशों के
            साथ सहयोग/सहयोग की दिशा में विदेश मंत्रालय (एमईटीए) और
            इलेक्ट्रॉनिक्स और आईटी मंत्रालय (माएटी) के साथ सक्रिय रूप से काम कर
            रहा है। आज तक सी-डैक ने अफ्रीका, पूर्वी यूरोप, दक्षिण-पूर्व एशिया,
            मध्य एशिया, मध्य पूर्व, अरब, लैटिन अमेरिका, कैरिबियन और प्रशांत
            द्वीप देशों के 40 देशों में विदेश मंत्रालय और इलेक्ट्रॉनिक्स और आईटी
            मंत्रालय के साथ घनिष्ठ सहयोग में कई परियोजनाओं को सफलतापूर्वक लागू
            किया है। इन परियोजनाओं ने सी-डैक के लिए ख्याति अर्जित की है और
            अंतर्राष्ट्रीय क्षेत्र में आईसीटी में भारतीय विशेषज्ञता को बढ़ावा
            दिया है।
          </p>
          <p align="justify">
            सी-डैक दिल्ली ने सितंबर 2020 में जसोला इंस्टीट्यूशनल एरिया, नई
            दिल्ली में अपने नए भवन में एडवांस्ड कंप्यूटिंग ट्रेनिंग स्कूल
            (एसीटी) की स्थापना की है और केंद्र और राज्य सरकार के तहत कई विदेशी
            एजेंसियों, मंत्रालयों / विभागों को कॉर्पोरेट प्रशिक्षण के अलावा
            घरेलू और अंतर्राष्ट्रीय छात्रों के लिए पीजी डिप्लोमा इन एडवांस्ड
            कंप्यूटिंग (पीजी-डैक) और पीजी डिप्लोमा इन बिग डेटा एनालिटिक्स
            (पीजी-डीबीडीए) की पेशकश कर रहा है।
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutCdac;
