import React from "react";
import "./NotFound.css";
import NotFoundImg from "../../assets/images/page_not_found.svg";

const NotFound = () => {
  return (
    <div className="container notFound">
      <img src={NotFoundImg} alt="Page Not Found" />
      <h1>Page Not Found</h1>
    </div>
  );
};

export default NotFound;
