import React from "react";
import { useNavigate } from "react-router-dom";
import "./Card.css";

const Card = (props) => {
  const { card } = props;

  const navigate = useNavigate();

  const viewPdf = (pdfPath) => {
    navigate(`/view`, { state: { path: pdfPath } });
  };

  // const handleOnClick = () => {
  //   fetch("");
  // };

  return (
    <div className="card" onClick={() => viewPdf(card.pdfPath)}>
      <header className="card-header">
        <h2>वर्ष {card.year} संस्करण</h2>
      </header>
      <div className="card__image">
        <img src={card.thumbnail} alt="mananBook"></img>
      </div>
      <div className="tags">
        <span /* onClick={handleOnClick} */>
          पुस्तक पढ़ने के लिए क्लिक करें
        </span>
      </div>
    </div>
  );
};

export default Card;
