import React from "react";
import Cardlist from "../../components/Home/Cardlist/Cardlist";
import Heroimage from "../../components/Home/Heroimage/Heroimage";
import "./Home.css";

const Home = () => {
  return (
    <div className="home__container">
      <div className="row gx-0">
        <Heroimage />
      </div>
      <div className="container">
        <div className="row heading">
          <h3 className="heading__title">मनन के संस्करण</h3>
        </div>
        <div className="row">
          <Cardlist />
        </div>
      </div>
    </div>
  );
};

export default Home;
