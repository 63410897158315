import React from "react";
import PDFReader from "../../components/Viewpage/PDFReader/PDFReader";

const Viewpage = () => {
  return (
    <div className="container">
      <PDFReader />
    </div>
  );
};

export default Viewpage;
