import React from "react";
import "../common.css";
import "./ContactUs.css";

const ContactUs = () => {
  return (
    <div className="container">
      <div className="alert alert-light box" role="alert">
        <div className="title">
          <h2>
            <b>संपर्क सूत्र</b>
          </h2>
        </div>
        <div className="content">
          <h5>
            <b>राजभाषा प्रकोष्ठ / Official Language Cell</b>
          </h5>
          <p>
            <i className="fa-solid fa-envelope-circle-check"></i>
            <b> ई-मेल / E-mail: </b>
            <span> delhindi[at]cdac[dot]in</span>
          </p>
          <p>
            <i className="fa-solid fa-globe"></i>
            <b> वेबसाईट / Website: </b>
            <a href="https://www.cdac.in/"> www.cdac.in</a>
          </p>
          <div>
            <p>
              <i className="fa-solid fa-map-location-dot"></i>
              <b> पता / Address: </b>
            </p>
            <ul>
              <li>
                <p align="justify">प्रगत संगणन विकास केन्द्र (सी-डैक)</p>
                <p align="justify">
                  भूखंड सं. 20, एफसी-33, संस्थानिक क्षेत्र, जसौला, नई दिल्ली -
                  110025, भारत
                </p>
              </li>
              <li>
                <p align="justify">
                  Centre For Development of Advanced Computing (C-DAC)
                </p>
                <p align="justify">
                  Plot No. 20, FC-33, Institutional Area, Jasola, New Delhi –
                  110025, India
                </p>
              </li>
            </ul>
          </div>
          <div>
            <p>
              <i className="fa-solid fa-phone"></i>
              <b> दूरभाष/ Tele.: </b>
            </p>
            <ul>
              <li>
                <p align="justify">+91-11-2987 9579 (सीधे/ Direct)</p>
              </li>
              <li>
                <p align="justify">+91-11-2694 0239 (विस्तार/ Ext.) 9579</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
