import React from "react";
import "./PDFReader.css";
import Loader from "../Loader/Loader";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import ControlPanel from "../ControlPanel/ControlPanel";

import { useLocation } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = "assets/worker/pdf.worker.min.js";

function PDFReader() {
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const { state } = useLocation();

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setIsLoading(false);
  }

  return (
    <div>
      <Loader isLoading={isLoading} />
      <section id="pdf-section" className="pdfView">
        <ControlPanel
          scale={scale}
          setScale={setScale}
          numPages={numPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
        <center>
          <Document
            file={state.path}
            renderMode="canvas"
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <div className="pages">
              <Page height={600} pageNumber={pageNumber} scale={scale} />
            </div>
          </Document>
        </center>
      </section>
    </div>
  );
}

export default PDFReader;
